import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import {
	FaHome,
	FaShoppingBasket,
	FaUserFriends,
	FaDollarSign,
	FaCog,
	FaStore,
} from 'react-icons/fa';

import ubrikkLogo from '../../assets/img/logo/logo_purple.png';

const Sidebar = () => {
	const { pathname } = useLocation();
	const accessLevel = useSelector((state) => state.auth.user.accessLevel);

	return (
		<Navbar
			bg='white'
			className='d-none d-lg-flex align-items-start justify-content-start w-special h-100 shadow'>
			<Container fluid className='flex-column'>
				<Navbar.Brand>
					<img src={ubrikkLogo} alt='ubrikk' width={150} className='mb-5 mt-3' />
				</Navbar.Brand>

				<Nav defaultActiveKey={pathname} className='flex-column mt-3 gap-2'>
					<Nav.Link as={Link} to='/' className='d-flex align-items-center border-bottom rounded'>
						<FaHome /> <span className='ms-3'>Dashboard</span>
					</Nav.Link>
					{accessLevel === 'admin' && (
						<>
							<Nav.Link
								as={Link}
								to='/customers'
								className='d-flex align-items-center border-bottom rounded'>
								<FaUserFriends /> <span className='ms-3'>Customers</span>
							</Nav.Link>
							<Nav.Link
								as={Link}
								to='/vendors'
								className='d-flex align-items-center border-bottom rounded'>
								<FaUserFriends /> <span className='ms-3'>Vendors</span>
							</Nav.Link>
						</>
					)}
					<Nav.Link
						as={Link}
						to='/orders'
						className='d-flex align-items-center border-bottom rounded'>
						<FaShoppingBasket /> <span className='ms-3'>Orders</span>
					</Nav.Link>
					{accessLevel === 'vendor' && (
						<>
							<Nav.Link
								as={Link}
								to='/clients'
								className='d-flex align-items-center border-bottom rounded'>
								<FaUserFriends /> <span className='ms-3'>Clients</span>
							</Nav.Link>
							<Nav.Link
								as={Link}
								to='/wallet'
								className='d-flex align-items-center border-bottom rounded'>
								<FaDollarSign /> <span className='ms-3'>Wallet</span>
							</Nav.Link>
						</>
					)}
					{accessLevel === 'admin' && (
						<Nav.Link
							as={Link}
							to='/account'
							className='d-flex align-items-center border-bottom rounded'>
							<FaDollarSign /> <span className='ms-3'>Wallets</span>
						</Nav.Link>
					)}
					<Nav.Link
						as={Link}
						to='/settings'
						className='d-flex align-items-center border-bottom rounded'>
						<FaCog /> <span className='ms-3'>Settings</span>
					</Nav.Link>
					<Nav.Link
						as={Link}
						to='/inventory'
						className='d-flex align-items-center border-bottom rounded'>
						<FaStore /> <span className='ms-3'>Inventory</span>
					</Nav.Link>
				</Nav>
			</Container>
		</Navbar>
	);
};

export default Sidebar;
