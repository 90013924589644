import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

import Breadcrumbs from './layouts/breadcrumb';

const Inventory = () => {
	return (
		<>
			<Breadcrumbs title='Inventory' />
			<Card
				as={Col}
				className='card-primary p-4'
				lg='10'
				md='12'
				sm='12'
				style={{
					boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
					transition: '0.3s',
				}}>
				<Card.Body>
					<div className='row'>
						<div className='col-lg-4'>
							<Row className='mb-2'>
								<Col className='d-grid gap-2' lg='10'>
									<Button size='sm'>NAMEPLATE</Button>
								</Col>
								<Col lg='2'>
									<BootstrapSwitchButton
										checked={true}
										size='sm'
										onstyle='success'
										offstyle='outline-danger'
									/>
								</Col>
							</Row>
							<Row className='mb-2'>
								<Col className='d-grid gap-2' lg='10'>
									<Button size='sm'>LABEL</Button>
								</Col>
								<Col lg='2'>
									<BootstrapSwitchButton
										checked={true}
										size='sm'
										onstyle='success'
										offstyle='outline-danger'
									/>
								</Col>
							</Row>
							<Row className='mb-2'>
								<Col className='d-grid gap-2' lg='10'>
									<Button size='sm'>TAPE</Button>
								</Col>
								<Col lg='2'>
									<BootstrapSwitchButton
										checked={true}
										size='sm'
										onstyle='success'
										offstyle='outline-danger'
									/>
								</Col>
							</Row>
							<Row className='mb-2'>
								<Col className='d-grid gap-2' lg='10'>
									<Button size='sm'>CAKE</Button>
								</Col>
								<Col lg='2'>
									<BootstrapSwitchButton
										checked={true}
										size='sm'
										onstyle='success'
										offstyle='outline-danger'
									/>
								</Col>
							</Row>
						</div>
						<div className='col-lg-1'></div>
						<div className='col-lg-6 ml4'>
							<Row>
								<Col lg='4' className='d-grid gap-2'>
									<Button variant='success' size='sm'>
										TABLE
									</Button>
								</Col>
								<Col lg='4' className='d-grid gap-2'>
									<Button variant='success' size='sm'>
										DOOR
									</Button>
								</Col>
								<Col lg='4' className='d-grid gap-2'>
									<Button variant='success' size='sm'>
										OFFICE
									</Button>
								</Col>
							</Row>
						</div>
					</div>
				</Card.Body>
				<Card.Footer>
					<Button variant='success'>Save Changes</Button>
				</Card.Footer>
			</Card>
		</>
	);
};
export default Inventory;
