import React from 'react';
import { useTable, usePagination } from 'react-table';

const Table = ({ columns, data }) => {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0, pageSize: 10 },
		},
		usePagination
	);

	return (
		<div>
			<table
				{...getTableProps()}
				className='card table table-condensed table-hover table-success'
				style={{ display: 'table' }}>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th key={column.id} className='text-center'>
									{column.render('Header')}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row, i) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()} key={i}>
								{row.cells.map((cell) => (
									<td
										{...cell.getCellProps()}
										className='text-center'
										style={{
											minWidth: cell.column.Header === 'Quantity' ? 50 : 105,
											verticalAlign: 'middle',
										}}>
										{cell.render('Cell')}
									</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</table>
			<ul className='pagination'>
				<li
					className='page-item'
					onClick={() => gotoPage(0)}
					disabled={!canPreviousPage}
					style={{ cursor: 'pointer' }}>
					<span className='page-link'>First</span>
				</li>
				<li
					className='page-item'
					onClick={() => previousPage()}
					disabled={!canPreviousPage}
					style={{ cursor: 'pointer' }}>
					<span className='page-link'>{'<'}</span>
				</li>
				<li
					className='page-item'
					onClick={() => nextPage()}
					disabled={!canNextPage}
					style={{ cursor: 'pointer' }}>
					<span className='page-link'>{'>'}</span>
				</li>
				<li
					className='page-item'
					onClick={() => gotoPage(pageCount - 1)}
					disabled={!canNextPage}
					style={{ cursor: 'pointer' }}>
					<span className='page-link'>Last</span>
				</li>
				<li>
					<span className='page-link'>
						Page{' '}
						<strong>
							{pageIndex + 1} of {pageOptions.length}
						</strong>{' '}
					</span>
				</li>
				<li>
					<span className='page-link'>
						<input
							className='form-control'
							type='number'
							defaultValue={pageIndex + 1}
							onChange={(e) => {
								const page = e.target.value ? Number(e.target.value) - 1 : 0;
								gotoPage(page);
							}}
							style={{ width: '100px', height: '20px' }}
						/>
					</span>
				</li>{' '}
				<select
					className='form-control'
					value={pageSize}
					onChange={(e) => {
						setPageSize(Number(e.target.value));
					}}
					style={{ width: '120px', height: '38px' }}>
					{[5, 10, 20, 30, 40, 50].map((pageSize) => (
						<option key={pageSize} value={pageSize}>
							Show {pageSize}
						</option>
					))}
				</select>
			</ul>
		</div>
	);
};
export default Table;
