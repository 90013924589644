import React, { useState } from 'react';
import { FaFastBackward, FaFastForward } from 'react-icons/fa';
import { useTable, usePagination } from 'react-table';
import ClientOrder from './client-order-preview';

const Table = ({ columns, data, data1 }) => {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		canNextPage,
		pageOptions,
		gotoPage,
		pageCount,
		state,
		canPreviousPage,
		prepareRow,
	} = useTable(
		{
			columns,
			data,
		},
		usePagination
	);

	const { pageIndex } = state;

	const [show, setShow] = useState(false);
	const [customer, setCustomer] = useState({});
	const [order, setOrder] = useState([]);

	const handleClose = () => setShow(false);

	const myorder = order.filter((item) => item.customerId === customer.ID);

	return (
		<>
			<ClientOrder show={show} handleClose={handleClose} details={customer} data1={myorder} />
			<table
				{...getTableProps()}
				className='card table table-striped table-condensed table-hover table-success striped-tabled-with-hover'
				style={{ display: 'table' }}>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th key={column.id}>{column.render('Header')}</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row, i) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()} key={i}>
								{row.cells.map((cell) => (
									<td
										onClick={(evt) => {
											evt.preventDefault();
											if (cell.column.id === 'orderUrl') {
												setOrder(data1);
												setCustomer({
													Name: row.original.name.toLocaleUpperCase(),
													ID: cell.value,
												});
												setShow(true);
											}
										}}
										{...cell.getCellProps()}
										style={{ verticalAlign: 'middle' }}>
										{cell.render('Cell')}
									</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</table>
			<div>
				<span>
					Page{' '}
					<strong>
						{pageIndex + 1} of {pageOptions.length}
					</strong>{' '}
				</span>
				<button
					onClick={() => gotoPage(0)}
					disabled={!canPreviousPage}
					style={{ borderTopLeftRadius: '50%', borderBottomLeftRadius: '50%' }}>
					<FaFastBackward />
				</button>{' '}
				<button onClick={() => previousPage()} disabled={!canPreviousPage}>
					previous
				</button>{' '}
				<span>
					{' '}
					Go to Page:{' '}
					<input
						type='number'
						defaultValue={pageIndex + 1}
						onChange={(e) => {
							const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
							gotoPage(pageNumber);
						}}
						style={{ width: '50px' }}
					/>
				</span>{' '}
				<button onClick={() => nextPage()} disabled={!canNextPage}>
					next
				</button>{' '}
				<button
					onClick={() => gotoPage(pageCount - 1)}
					disabled={!canNextPage}
					style={{
						borderTopRightRadius: '50%',
						borderBottomRightRadius: '50%',
					}}>
					<FaFastForward />
				</button>
			</div>
		</>
	);
};
export default Table;
