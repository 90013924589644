import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';

import Breadcrumbs from './layouts/breadcrumb';
import { backendURL } from '../config/data';
import Table from '../components/client-table';
import { FaEye } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const Client = () => {
	const token = useSelector((state) => state.auth.token);
	const [client, setClient] = useState([]);
	const [loading, setLoading] = useState(false);
	const [_data, _setData] = useState([]);

	useEffect(() => {
		setLoading(true);

		const fetchClients = async () => {
			await axios({
				method: 'GET',
				baseURL: `${backendURL}/api/order`,
				headers: { Authorization: `Bearer ${token}` },
			})
				.then((response) => {
					const customers = response.data.orders.map((item) => {
						return {
							customerID: item.customerId,
							...item.contact,
							orderUrl: item.customerId,
							orderID: item._id,
							orderStatus: item.orderStatus,
							service: item.service,
							date: item.orderDate,
							quantity: item.cost.quantity,
						};
					});
					const clientID = customers.map((id) => id.customerID);
					_setData(response.data.orders);
					const filteredClient = customers.filter(
						({ customerID }, index) => !clientID.includes(customerID, index + 1)
					);
					setClient(filteredClient);
					setLoading(false);
				})
				.catch((error) => {
					toast.error(error.response?.data || error.message);
					setLoading(false);
				});
		};
		fetchClients();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/*Columns*/

	const columns = [
		{ Header: 'Name', accessor: 'name' },
		{ Header: 'Address', accessor: 'address' },
		{ Header: 'Phone', accessor: 'phone' },
		{ Header: 'State', accessor: 'state' },
		{ Header: 'Zip', accessor: 'zip' },
		{
			Header: 'Orders',
			accessor: 'orderUrl',
			url: true,
			Cell: (e) => (
				<Link
					style={{ textTransform: 'none', textDecoration: 'none' }}
					title='view orders'
					to={e.value}
					onClick={(evt) => evt.preventDefault()}>
					<FaEye />
					&nbsp;view
				</Link>
			),
		},
	];
	/*Columns*/

	return (
		<>
			<Breadcrumbs title='Clients' />
			<Container>
				<Row>
					<Col md='12' className='table-full-width table-responsive px-0'>
						{loading ? (
							<h2 className='text-center'>fetching clients, please wait...</h2>
						) : client.length === 0 ? (
							<h2 className='text-center'>no client availlable</h2>
						) : (
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-responsive table-responsive-xxl'>
								<Table columns={columns} data={client} data1={_data} />
							</div>
						)}
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default Client;
