import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';

import CustomersTable from '../components/customers-table';
import { backendURL } from '../config/data';
import Breadcrumbs from './layouts/breadcrumb';
import { useSelector } from 'react-redux';

const columns = [
	{ Header: 'Id', accessor: '_id' },
	{ Header: 'Email', accessor: 'email' },
	{ Header: 'Date registered', accessor: 'createdAt' },
	{Header: 'Place registered'}
];
const Customers = () => {
	const [loading, setLoading] = React.useState(false);
	const { token } = useSelector((state) => state.auth);
	const [data, setData] = React.useState([]);

	React.useEffect(() => {
		async function getCustomers() {
			setLoading(true);
			axios({
				method: 'GET',
				url: `${backendURL}/api/user/customers`,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
				.then((response) => {
					const customers = response.data.customers.map((customer) => {
						const year = customer.createdAt.split('T')[0].slice(0, 4);
						const month = customer.createdAt.split('T')[0].slice(5, 7);
						customer.createdAt = month + '/' + year;
						return { ...customer, url: customer._id };
					});
					setData(customers);
					setLoading(false);
				})
				.catch((error) => {
					toast.error(error.response?.data || error.message);
					setLoading(false);
				});
		}
		getCustomers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Breadcrumbs title='Customers' />
			<Container className='py-3 fluid'>
				{loading ? (
					<h2 className='text-center'>fetching customers, please wait...</h2>
				) : data.length === 0 ? (
					<h2 className='text-center text-white'>no customers found</h2>
				) : (
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-responsive table-responsive-xxl'>
						<CustomersTable columns={columns} data={data} />
					</div>
				)}
			</Container>
		</>
	);
};

export default Customers;
