import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import {
	FaShoppingBasket,
	FaUserFriends,
	FaDollarSign,
	FaCog,
	FaStore,
	FaSignOutAlt,
	FaFileAlt
} from 'react-icons/fa';
import ChangePassword from '../../components/change-password';
import ubrikkLogo from '../../assets/img/logo/logo_white.png';
import defaultUserImg from '../../assets/img/profile/team-4.jpg';
import { logout } from '../../redux/reducers/auth';

const Dashboard = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { profile, accessLevel } = useSelector((state) => state.auth.user);

	// Change password modal
	const [show, setShow] = React.useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<>
			<Navbar as='header' bg={accessLevel === 'admin' ? 'warning' : 'primary'} className='shadow'>
				<Container fluid className='px-lg-5'>
					<Navbar.Brand className='d-lg-none'>
						<img src={ubrikkLogo} alt='ubrikk' width={115} />
					</Navbar.Brand>

					<Dropdown align='end' className='ms-auto'>
						<Dropdown.Toggle id='easyNav' className='py-0 custom-dropdown'>
							<img src={profile?.imageUrl || defaultUserImg} alt={profile?.userName} />
							<span className='ms-2 h6 text-white'>{profile?.userName}</span>
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Dropdown.Item as={Link} to='/'>
								<FaUserFriends /> Update Profile
							</Dropdown.Item>
							
							{accessLevel === 'admin' && (
								<>
									<Dropdown.Item as={Link} to='/customers' className='d-lg-none'>
										<FaUserFriends /> Customers
									</Dropdown.Item>
									<Dropdown.Item as={Link} to='/vendors' className='d-lg-none'>
										<FaDollarSign /> Vendors
									</Dropdown.Item>
									
									<Dropdown.Item as={Link} to='/orders' className='d-lg-none'>
										<FaShoppingBasket /> Orders
									</Dropdown.Item>

									<Dropdown.Item as={Link} to='/wallet' className='d-lg-none'>
										<FaDollarSign /> Wallet
									</Dropdown.Item>

									<Dropdown.Item as={Link} to='/settings' className='d-lg-none'>
										<FaCog /> Settings
									</Dropdown.Item>	
								</>
							)}
							{accessLevel === 'vendor' && (
								<>
									<Dropdown.Item as={Link} to='/clients' className='d-lg-none'>
										<FaUserFriends /> Clients
									</Dropdown.Item>
									<Dropdown.Item as={Link} to='/wallet' className='d-lg-none'>
										<FaDollarSign /> Wallet
									</Dropdown.Item>
									<Dropdown.Item as={Link} to='/orders' className='d-lg-none'>
										<FaFileAlt /> Orders
									</Dropdown.Item>
									<Dropdown.Item as={Link} to='/settings' className='d-lg-none'>
										<FaCog /> Settings
									</Dropdown.Item>
								</>
							)}

							<Dropdown.Item as={Link} to='/inventory' className='d-lg-none'>
								<FaStore /> Inventory
							</Dropdown.Item>

							<Dropdown.Divider />
							<Dropdown.Item onClick={handleShow}>
								<FaCog /> Change Password
							</Dropdown.Item>

							<Dropdown.Item
								onClick={() => {
									dispatch(logout());
									navigate('/');
								}}>
								<FaSignOutAlt /> Logout
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</Container>
			</Navbar>
			<ChangePassword show={show} handleClose={handleClose} />
		</>
	);
};

export default Dashboard;
