import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { FaKey } from 'react-icons/fa';

import useQuery from '../hooks/use-query';
import { backendURL } from '../config/data';

const defaultValue = { password: '', rePassword: '' };

const ResetPassword = () => {
	const navigate = useNavigate();
	const query = useQuery();
	const token = query.get('token');
	const [formData, setData] = React.useState(defaultValue);

	const handleChange = (e) => setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!formData.password || !formData.rePassword) return toast.error('Fields cannot be empty');
		if (formData.password !== formData.rePassword) return toast.error('Passwords are different');
		try {
			const res = await axios.put(`${backendURL}/api/user/resetpassword/${token}`, {
				password: formData.password,
			});
			toast.success(res.data.message);
			navigate('/login');
		} catch (error) {
			console.log({ error });
			toast.error(error?.response?.data?.message || error.message);
			setData(defaultValue);
		}
	};

	return (
		<Container as='main' className='d-flex flex-grow-1 justify-content-center align-items-center'>
			<Col as={Card} xs={12} lg={5} className='px-md-4 py-md-3'>
				<Card.Body>
					<Card.Title className='mb-3 text-center'>Reset Password</Card.Title>
					<p className='text-center'>Please eneter new password below</p>
					<Form onSubmit={handleSubmit}>
						<Form.Group className='mb-3' controlId='password'>
							<Form.Label>Confrim password</Form.Label>
							<InputGroup>
								<InputGroup.Text>
									<FaKey />
								</InputGroup.Text>
								<Form.Control
									type='password'
									name='password'
									value={formData.password}
									onChange={handleChange}
								/>
							</InputGroup>
						</Form.Group>

						<Form.Group className='mb-3' controlId='rePassword'>
							<Form.Label>Enter new password</Form.Label>
							<InputGroup>
								<InputGroup.Text>
									<FaKey />
								</InputGroup.Text>
								<Form.Control
									type='password'
									name='rePassword'
									value={formData.rePassword}
									onChange={handleChange}
								/>
							</InputGroup>
						</Form.Group>
						<Button variant='primary' type='submit' className='w-100 my-4'>
							Reset
						</Button>
					</Form>
				</Card.Body>
			</Col>
		</Container>
	);
};

export default ResetPassword;
