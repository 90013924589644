import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendURL } from '../../config/data';

export const authUser = createAsyncThunk(
	'auth/authenticateUser',
	async (token, { rejectWithValue }) => {
		try {
			const res = await axios.get(`${backendURL}/api/user`, {
				headers: { Authorization: `Bearer ${token}` },
			});
			return res.data;
		} catch (err) {
			if (!err.response) throw err;
			return rejectWithValue(err.response.data);
		}
	}
);

export const signByCreds = createAsyncThunk(
	'auth/signByCredentials',
	async ({ endpoint, creds }, { rejectWithValue }) => {
		try {
			const res = await axios.post(`${backendURL}/api/user/${endpoint}`, creds);
			return res.data;
		} catch (err) {
			if (!err.response) throw err;
			return rejectWithValue(err.response.data);
		}
	}
);

const initialState = {
	loading: false,
	authenticated: false,
	user: null,
	token: localStorage.getItem('ubk-tkn-vndr'),
	error: null,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: (state) => {
			localStorage.removeItem('ubk-tkn-vndr');
			state.loading = false;
			state.authenticated = false;
			state.user = null;
			state.token = null;
			state.error = null;
		},
		updateUserProfile: (state, action) => {
			state.user = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(signByCreds.pending, (state) => {
			state.loading = true;
			state.error = null;
		});
		builder.addCase(signByCreds.fulfilled, (state, action) => {
			state.loading = false;
			state.authenticated = true;
			state.user = action.payload.user;
			state.token = action.payload.token;
			localStorage.setItem('ubk-tkn-vndr', action.payload.token);
		});
		builder.addCase(signByCreds.rejected, (state, action) => {
			state.loading = false;
			state.authenticated = false;
			state.user = null;
			state.token = null;
			state.error = action.payload?.message;
		});
		builder.addCase(authUser.pending, (state) => {
			state.loading = true;
			state.error = null;
		});
		builder.addCase(authUser.fulfilled, (state, action) => {
			state.loading = false;
			state.authenticated = true;
			state.user = action.payload.user;
			state.token = localStorage.getItem('ubk-tkn-vndr');
		});
		builder.addCase(authUser.rejected, (state, action) => {
			state.loading = false;
			state.authenticated = false;
			state.user = null;
			state.token = null;
			state.error = action.payload?.message;
			localStorage.removeItem('ubk-tkn-vndr');
		});
	},
});

export const { logout, updateUserProfile } = authSlice.actions;

export default authSlice.reducer;
