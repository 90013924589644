import React from 'react';
import { Badge, Col, Container, Modal, Row } from 'react-bootstrap';
import Table from './client-order-table';

const ClientOrder = ({ show, handleClose, details, data1 }) => {
	const columns = [
		{ Header: 'Order Id', accessor: '_id', Cell: (e) => e.value.slice(0, 10) },
		{ Header: 'Service', accessor: 'service' },
		{ Header: 'Quantity', accessor: 'cost.quantity' },
		{ Header: 'Date', accessor: 'orderDate', Cell: (e) => e.value.slice(0, -14) },
		{
			Header: 'Status',
			accessor: 'orderStatus',
			Cell: (e) => (
				<Badge
					bg={
						e.value === 'pending'
							? 'primary'
							: e.value === 'completed'
							? 'success'
							: e.value === 'in-transit'
							? 'info'
							: 'danger'
					}
					className='text-uppercase'>
					{e.value}
				</Badge>
			),
		},
	];

	return (
		<>
			<Row as={Modal} show={show} onHide={handleClose} centered size={'lg'} animation>
				<Modal.Header closeButton>
					<Modal.Title>Customer : {details.Name}</Modal.Title>
				</Modal.Header>

				<Container as={Modal.Body}>
					<Row>
						<Col md='12' sm='12' lg='12' className='table-full-width table-responsive'>
							<Table
								columns={columns}
								data={data1}
								className="className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-responsive table-responsive-xxl"
							/>
						</Col>
					</Row>
				</Container>
			</Row>
		</>
	);
};

export default ClientOrder;
