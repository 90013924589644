import React from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Breadcrumbs from './layouts/breadcrumb';

const Settings = () => {
	const btnStyle = {
		margin: 'auto',
		color: '#FFF',
		backgroundColor: '#5e72e4',
	};

	return (
		<>
			<Breadcrumbs title='Settings' />
			<Card as={Col} className='p-4' lg='10' md='10' sm='10' xs='10'>
				<Row className='mb-4'>
					<Button as={Link} to='/' className='col-xs-12 col-sm-10 col-md-6 col-lg-4' style={btnStyle}>
						GENERAL
					</Button>
				</Row>
				<Row className='mb-4'>
					<Button as={Link} to='/' className='col-xs-12 col-sm-10 col-md-6 col-lg-4' style={btnStyle}>
						BILLING
					</Button>
				</Row>
				<Row className='mb-4'>
					<Button as={Link} to='/' className='col-xs-12 col-sm-10 col-md-6 col-lg-4' style={btnStyle}>
						SECURITY
					</Button>
				</Row>
				<Row>
					<Button as={Link} to='/' className='col-xs-12 col-sm-10 col-md-6 col-lg-4' style={btnStyle}>
						NOTIFICATION
					</Button>
				</Row>
			</Card>
		</>
	);
};

export default Settings;
