import * as React from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Modal, Form, InputGroup } from 'react-bootstrap';
import { FaKey } from 'react-icons/fa';
import { backendURL } from '../config/data';
import { useEffect } from 'react';

const ChangePassword = ({ show, handleClose }) => {
	const token = useSelector((state) => state.auth.token);
	const [oldPassword, setOldPassword] = React.useState('');
	const [newPassword, setNewPassword] = React.useState('');
	const [confirmNewPassword, setConfirmNewPassword] = React.useState('');

	// Handle the change password form submission
	const handleChangePassword = async (e) => {
		e.preventDefault();

		// Validation of the input passwords
		if (!oldPassword || !newPassword || !confirmNewPassword)
			return toast.error('Fields cannot be empty');
		if (newPassword !== confirmNewPassword) return toast.error("Sorry! Password didn't match");
		if (
			!/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{6,255}$/.test(
				newPassword
			)
		)
			return toast.error(
				'The new password should contain at least 1 uppercase, 1 lowercase, 1 numeral and a special char'
			);
		try {
			const res = await axios.patch(
				`${backendURL}/api/user`,
				{ password: oldPassword, newPassword },
				{ headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } }
			);
			toast.info(res.data.message);
			handleClose();
		} catch (error) {
			toast.error(error?.response?.data?.message || error.message);
		}
	};

	// useEffect cleanup function to clear password input
	useEffect(() => {
		return () => {
			setOldPassword('');
			setNewPassword('');
			setConfirmNewPassword('');
		};
	}, []);

	return (
		<>
			<Modal show={show} onHide={handleClose} centered>
				{/* Modal header */}
				<Modal.Header className='bg-primary text-white' closeButton>
					<Modal.Title>Change your password</Modal.Title>
				</Modal.Header>
				<Form onSubmit={handleChangePassword}>
					{/* Modal body */}
					<Modal.Body>
						{/* Old password field */}
						<Form.Group className='mb-3' controlId='formOldPassword'>
							<Form.Label>Old Password</Form.Label>
							<InputGroup>
								<InputGroup.Text>
									<FaKey />
								</InputGroup.Text>
								<Form.Control
									type='password'
									name='oldPassword'
									placeholder='Enter old password'
									value={oldPassword}
									onChange={(e) => setOldPassword(e.target.value)}
								/>
							</InputGroup>
						</Form.Group>

						{/* New password field */}
						<Form.Group className='mb-3' controlId='formNewPassword'>
							<Form.Label>New Password</Form.Label>
							<InputGroup>
								<InputGroup.Text>
									<FaKey />
								</InputGroup.Text>
								<Form.Control
									type='password'
									name='newPassword'
									placeholder='Enter new password'
									value={newPassword}
									onChange={(e) => setNewPassword(e.target.value)}
								/>
							</InputGroup>
						</Form.Group>

						{/* Confirm new password field */}
						<Form.Group className='mb-3' controlId='formConfirmNewPassword'>
							<Form.Label>Re-enter New Password</Form.Label>
							<InputGroup>
								<InputGroup.Text>
									<FaKey />
								</InputGroup.Text>
								<Form.Control
									type='password'
									name='confirmNewPassword'
									placeholder='Re-enter new password'
									value={confirmNewPassword}
									onChange={(e) => setConfirmNewPassword(e.target.value)}
								/>
							</InputGroup>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button variant='danger' type='submit'>
							Change Password
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default ChangePassword;
