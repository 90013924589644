import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';

import WalletTable from '../components/wallet-table';
import { backendURL } from '../config/data';
import Breadcrumbs from './layouts/breadcrumb';

const columns = [
	{ Header: 'Customer name', accessor: 'contact.name' },
	{ Header: 'Service', accessor: 'service' },
	{ Header: 'Quantity', accessor: 'cost.quantity' },
	{ Header: 'Service Cost', accessor: 'cost.order' },
	{ Header: 'Delivery Cost', accessor: 'cost.delivery' },
	{
		Header: 'Status',
		accessor: 'orderStatus',
		Cell: (e) => (
			<Badge
				bg={e.value === 'Pending' ? 'warning' : (e.value = 'Completed' ? 'success' : 'danger')}
				className='text-uppercase'>
				{e.value}
			</Badge>
		),
	},
];
const Wallet = () => {
	const [loading, setLoading] = React.useState(false);
	const [data, setData] = React.useState([]);

	React.useEffect(() => {
		async function getOrders() {
			setLoading(true);
			axios({
				method: 'GET',
				url: `${backendURL}/api/orders`,
			})
				.then((response) => {
					const orders = response.data.map((order) => {
						return { ...order, url: order._id };
					});
					setData(orders);
					setLoading(false);
				})
				.catch((error) => {
					toast.error(error.response?.data || error.message);
					setLoading(false);
				});
		}
		getOrders();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Breadcrumbs title='Wallet' />
			<Container className='py-3 fluid'>
				{loading ? (
					<h2 className='text-center'>fetching payments, please wait...</h2>
				) : data.length === 0 ? (
					<h2 className='text-center'>no payments found</h2>
				) : (
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-responsive table-responsive-xxl'>
						<WalletTable columns={columns} data={data} />
					</div>
				)}
			</Container>
		</>
	);
};

export default Wallet;
