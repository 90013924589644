import * as React from 'react';

const Loader = () => {
	return (
		<main className='h-100 w-100 d-flex flex-column justify-content-center align-items-center'>
			<h1 className='text-white'>Loading.....</h1>
		</main>
	);
};

export default Loader;
