import { FaFastBackward, FaFastForward } from 'react-icons/fa';
import { usePagination, useTable } from 'react-table';
export default function Table({ columns, data }) {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		canNextPage,
		pageOptions,
		gotoPage,
		pageCount,
		state,
		canPreviousPage,
		prepareRow,
	} = useTable(
		{
			columns,
			data,
		},
		usePagination
	);

	const { pageIndex } = state;

	/*const {
     getTableProps, 
     getTableBodyProps, 
     headerGroups, 
     rows, 
     prepareRow 
   } = useTable({
     columns,
     data
   });*/

	return (
		<>
			<table
				{...getTableProps()}
				className='card table table-primary table-condensed table-hover table-striped'
				style={{ display: 'table' }}>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th {...column.getHeaderProps()}>{column.render('Header')}</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row, i) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map((cell) => {
									return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
			<div>
				<span>
					Page{' '}
					<strong>
						{pageIndex + 1} of {pageOptions.length}
					</strong>{' '}
				</span>
				<button
					onClick={() => gotoPage(0)}
					disabled={!canPreviousPage}
					style={{ borderTopLeftRadius: '50%', borderBottomLeftRadius: '50%' }}>
					<FaFastBackward />
				</button>{' '}
				<button onClick={() => previousPage()} disabled={!canPreviousPage}>
					previous
				</button>{' '}
				<span>
					{' '}
					Go to Page:{' '}
					<input
						type='number'
						defaultValue={pageIndex + 1}
						onChange={(e) => {
							const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
							gotoPage(pageNumber);
						}}
						style={{ width: '50px' }}
					/>
				</span>{' '}
				<button onClick={() => nextPage()} disabled={!canNextPage}>
					next
				</button>{' '}
				<button
					onClick={() => gotoPage(pageCount - 1)}
					disabled={!canNextPage}
					style={{
						borderTopRightRadius: '50%',
						borderBottomRightRadius: '50%',
					}}>
					<FaFastForward />
				</button>
			</div>
		</>
	);
}
