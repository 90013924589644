import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const Breadcrumbs = (props) => {
	return (
		<Container>
			<Row className='mt-1 mb-4 mx-auto w-100 p-2 rounded-3 bg-white align-items-center'>
				<Col xs='12' md='7'>
					<h2>{props.title}</h2>
				</Col>
				<Col xs='6' md='5' className='d-none d-md-inline mt-3'>
					<Breadcrumb>
						<Breadcrumb.Item>Dashboard</Breadcrumb.Item>
						<Breadcrumb.Item active>{props.title}</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
		</Container>
	);
};

export default Breadcrumbs;
