import React from 'react';
import Button from 'react-bootstrap/Button';
import { FaFilePdf } from 'react-icons/fa';

const GenericPdfDownloader = ({ bodyRef, createPdf }) => {
	const exportPDF = () => createPdf(bodyRef.current);
	return (
		<Button variant='light' className='d-flex align-items-center' onClick={exportPDF}>
			Export <FaFilePdf className='ms-2' />
		</Button>
	);
};

export default GenericPdfDownloader;
