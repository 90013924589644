import React from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import { FaInfo } from 'react-icons/fa';

import Table from '../components/order-table';
import { backendURL } from '../config/data';
import Breadcrumbs from './layouts/breadcrumb';

const columns = [
	{ Header: 'Order Id', accessor: '_id', Cell: (e) => e.value.slice(0, 10) },
	{ Header: 'Service', accessor: 'service' },
 
	{ Header: 'Order Date', accessor: 'orderDate', Cell: (e) => e.value.slice(0, -14) },
	{ Header: 'Delivery ', },
	{
		Header: 'Order Status',
		accessor: 'orderStatus',
		Cell: (e) => (
			<Badge
				bg={
					e.value === 'pending'
						? 'primary'
						: e.value === 'completed'
						? 'success'
						: e.value === 'in-transit'
						? 'info'
						: 'danger'
				}
				className='text-uppercase'>
				{e.value}
			</Badge>
		),
	},
	{
		Header: 'Info',
		accessor: 'url',
		url: true,
		Cell: (e) => (
			<Link
				style={{ textTransform: 'none', textDecoration: 'none' }}
				title='more details'
				to={e.value}>
				<FaInfo />
				&nbsp;Details
			</Link>
		),
	},
];

const Orders = () => {
	const token = useSelector((state) => state.auth.token);
	const [loading, setLoading] = React.useState(false);
	const [data, setData] = React.useState([]);

	React.useEffect(() => {
		async function getOrders() {
			setLoading(true);
			axios({
				method: 'GET',
				url: `${backendURL}/api/order`,
				headers: { Authorization: `Bearer ${token}` },
			})
				.then((response) => {
					const orders = response.data.orders.map((order) => {
						return { ...order, url: order._id };
					});
					setData(orders);
					setLoading(false);
				})
				.catch((error) => {
					toast.error(error.response?.data || error.message);
					setLoading(false);
				});
		}
		getOrders();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Breadcrumbs title='Orders' />
			<Container className='py-3 fluid'>
				{loading ? (
					<h2 className='text-center'>fetching orders, please wait...</h2>
				) : data.length === 0 ? (
					<h2 className='text-center'>no order has been made</h2>
				) : (
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-responsive table-responsive-xxl'>
						<Table columns={columns} data={data} />
					</div>
				)}
			</Container>
		</>
	);
};

export default Orders;
