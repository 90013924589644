import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import DashboardLayout from './routes/layouts/dashboard';
import Home from './routes/home';
import Orders from './routes/orders';
import OrderDetails from './routes/order';
import Clients from './routes/clients';
import Wallet from './routes/wallet';
import Customers from './routes/customers';
import Vendors from './routes/vendors';
import Settings from './routes/settings';
import Inventory from './routes/inventory';
import ResetPassword from './routes/reset-password';
import Login from './routes/login';
import NotFound from './routes/404';
import { authUser } from './redux/reducers/auth';

const App = () => {
	const dispatch = useDispatch();
	const { authenticated, error } = useSelector((state) => state.auth);
	const token = localStorage.getItem('ubk-tkn-vndr');

	React.useEffect(() => {
		if (process.env.NODE_ENV !== 'development') {
			const script1 = document.createElement('script');
			script1.src = `https://www.googletagmanager.com/gtag/js?id=G-HHTT66KKYF`;
			script1.async = true;

			const script2 = document.createElement('script');
			script2.innerHTML = `
				window.dataLayer = window.dataLayer || [];
				function gtag(){
					dataLayer.push(arguments);
				}
				gtag('js', new Date());
				gtag('config', 'G-HHTT66KKYF');
			`;
			document.body.appendChild(script1);
			document.body.appendChild(script2);
		}
	}, []);

	React.useEffect(() => {
		if (error) toast.error(error);
	}, [error]);

	React.useEffect(() => {
		if (token) dispatch(authUser(token));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);

	return (
		<Routes>
			<Route path='/' element={authenticated ? <DashboardLayout /> : <Login />}>
				<>
					<Route index element={<Home />} />
					<Route path='orders' element={<Orders />} />
					<Route path='orders/:id' element={<OrderDetails />} />
					<Route path='clients' element={<Clients />} />
					<Route path='vendors' element={<Vendors />} />
					<Route path='wallet' element={<Wallet />} />
					<Route path='account' element={<Wallet />} />
					<Route path='customers' element={<Customers />} />
					<Route path='vendors' element={<Vendors />} />
					<Route path='settings' element={<Settings />} />
					<Route path='inventory' element={<Inventory />} />
				</>
			</Route>
			<Route path='resetpassword' element={<ResetPassword />} />
			<Route path='*' element={<NotFound />} />
		</Routes>
	);
};

export default App;
