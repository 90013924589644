import * as React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { FaEnvelope, FaKey } from 'react-icons/fa';
import Loader from '../components/loader';
import ForgotPassword from '../components/forgot-password';
import { signByCreds } from '../redux/reducers/auth';

const defaultValue = {
	email: '',
	password: '',
};

const Login = () => {
	const dispatch = useDispatch();
	const { loading, authenticated } = useSelector((state) => state.auth);
	const [formData, setData] = React.useState(defaultValue);
	const [error, setError] = React.useState();
	const [accessLevel, setLevel] = React.useState('vendor');

	const [show, setShow] = React.useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setData((prev) => ({ ...prev, [name]: value }));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!formData.email || !formData.password)
			return setError({ msg: 'Fields cannot be empty', type: 'warn' });
		dispatch(signByCreds({ endpoint: 'signin', creds: { ...formData, accessLevel } }));
		setData(defaultValue);
	};

	React.useEffect(() => {
		if (error) {
			toast[error.type](error.msg);
		}
		return () => {
			setError();
		};
	}, [error]);

	if (!loading || authenticated)
		return (
			<Container as='main' className='d-flex flex-grow-1 justify-content-center align-items-center'>
				<Col as={Card} xs={12} lg={5} className='px-md-4 py-md-3'>
					<Card.Body>
						<Card.Title className='mb-3 text-center fs-1 fw-bold'>Login</Card.Title>

						{/* Choose role */}
						<br />
						<ButtonGroup className='d-flex justify-content-center mb-4 gap-2'>
							<ToggleButton
								id='toggle-check'
								type='radio'
								name='vendor'
								variant='outline-primary'
								checked={accessLevel === 'vendor'}
								value='vendor'
								onClick={() => setLevel('vendor')}>
								as Vendor
							</ToggleButton>
							<ToggleButton
								id='toggle-check'
								type='radio'
								name='admin'
								variant='outline-primary'
								checked={accessLevel === 'admin'}
								value='admin'
								onClick={() => setLevel('admin')}>
								as Admin
							</ToggleButton>
						</ButtonGroup>

						{/* Login form */}
						<Form onSubmit={handleSubmit}>
							<Form.Group className='mb-3' controlId='formBasicEmail'>
								<Form.Label>Email address</Form.Label>
								<InputGroup>
									<InputGroup.Text>
										<FaEnvelope />
									</InputGroup.Text>
									<Form.Control
										type='email'
										name='email'
										placeholder='Enter email'
										value={formData.email}
										onChange={handleChange}
									/>
								</InputGroup>
							</Form.Group>

							<Form.Group className='mb-3' controlId='formBasicPassword'>
								<Form.Label>Password</Form.Label>
								<InputGroup>
									<InputGroup.Text>
										<FaKey />
									</InputGroup.Text>
									<Form.Control
										type='password'
										name='password'
										placeholder='Password'
										value={formData.password}
										onChange={handleChange}
									/>
								</InputGroup>
							</Form.Group>
							<Button variant='primary' type='submit' className='w-100 my-4'>
								Submit
							</Button>
						</Form>

						<div className='d-flex justify-content-between align-items-center'>
							<Button variant='link' className='text-primary' onClick={handleShow}>
								<small>Forgot password?</small>
							</Button>
							<Link to='/register'>
								<small>Create new account</small>
							</Link>
						</div>
					</Card.Body>
					<ForgotPassword show={show} handleClose={handleClose} />
				</Col>
			</Container>
		);

	return <Loader />;
};

export default Login;
