import React from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { savePDF } from '@progress/kendo-react-pdf';

import GenericPdfDownloader from '../components/generic-pdf-downloader';
import Breadcrumbs from './layouts/breadcrumb';
import { backendURL } from '../config/data';

const OrderDetails = () => {
	const token = useSelector((state) => state.auth.token);
	const pathArray = window.location.pathname.split('/');
	const _id = pathArray[2];
	const [order, setOrder] = React.useState([]);
	const [isLoading, setisLoading] = React.useState(true);
	const bodyRef = React.createRef();

	const createPdf = (html) => {
		savePDF(html, {
			paperSize: 'Letter',
			fileName: `order#${_id}.pdf`,
			margin: 3,
		});
	};

	React.useEffect(() => {
		async function getOrder() {
			await axios({
				method: 'GET',
				url: `${backendURL}/api/order/${_id}`,
				headers: { Authorization: `Bearer ${token}` },
			})
				.then((response) => {
					setOrder(response.data.order);
					setisLoading(false);
				})
				.catch((error) => {
					toast.error(error.response?.data || error.message);
				});
		}
		if (isLoading) getOrder();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Breadcrumbs title='Order' />
			<Container>
				<Container className='d-flex justify-content-between pb-2'>
					<Button
						as={Link}
						to='/orders'
						variant='light'
						className='d-flex align-items-center text-decoration-none'>
						<FaArrowCircleLeft className='me-2' />
						Back
					</Button>

					<GenericPdfDownloader bodyRef={bodyRef} createPdf={createPdf} />
				</Container>
				{isLoading ? (
					<h1 className='text-center'>fetching orders, please wait...</h1>
				) : (
					<Row as={Card} className='p-2 mx-3 mb-5' ref={bodyRef}>
						<table className='table table-striped table-responsive'>
							<thead>
								<tr>
									<th colSpan='2' className='h5'>
										Order
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className='w-25'>Service</td>
									<td>{order.service}</td>
								</tr>
								<tr>
									<td>Order Number</td>
									<td>{order._id}</td>
								</tr>
								<tr>
									<td>Date</td>
									<td>{order.orderDate.slice(0, -14)}</td>
								</tr>
								<tr>
									<td>Status</td>
									<td>{order.orderStatus}</td>
								</tr>
							</tbody>
						</table>

						<table className='table table-striped table-responsive'>
							<thead>
								<tr>
									<th colSpan='2' className='h5'>
										Customization
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className='w-25'>Material</td>
									<td>{order.customization.material.name}</td>
								</tr>
								<tr>
									<td>Size</td>
									<td>{order.customization.material.size}</td>
								</tr>
								<tr>
									<td>Thickness</td>
									<td>{order.customization.material.thickness}</td>
								</tr>
								<tr>
									<td>Design</td>
									<td>
										<img
											src={order.customization.design.url}
											alt='design'
											className='img-fluid'
											style={{ maxHeight: 250 }}
										/>
									</td>
								</tr>
							</tbody>
						</table>

						<table className='table table-responsive table-striped'>
							<thead>
								<tr>
									<th colSpan='2' className='h5'>
										Billing
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className='w-25'>Delivery</td>
									<td>{order.cost.delivery}</td>
								</tr>
								<tr>
									<td>Order</td>
									<td>{order.cost.order}</td>
								</tr>
								<tr>
									<td>Quantity</td>
									<td>{order.cost.quantity}</td>
								</tr>
							</tbody>
						</table>

						<table className='table table-striped table-responsive'>
							<thead>
								<tr>
									<th colSpan='2' className='h5'>
										Contacts
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className='w-25'>Name</td>
									<td>{order.contact.name}</td>
								</tr>
								<tr>
									<td>Phone Number</td>
									<td>{order.contact.phone}</td>
								</tr>
								<tr>
									<td>Address</td>
									<td>{order.contact.address}</td>
								</tr>
								<tr>
									<td>City</td>
									<td>{order.contact.city}</td>
								</tr>
								<tr>
									<td>State</td>
									<td>{order.contact.state}</td>
								</tr>
								<tr>
									<td>Zip Code</td>
									<td>{order.contact.zip}</td>
								</tr>
							</tbody>
						</table>
					</Row>
				)}
			</Container>
		</>
	);
};

export default OrderDetails;
