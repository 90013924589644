import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';

import VendorsTable from '../components/vendors-table';
import { backendURL } from '../config/data';
import Breadcrumbs from './layouts/breadcrumb';
import { useSelector } from 'react-redux';

const columns = [
	{ Header: 'Id', accessor: '_id' },
	{ Header: 'Name', accessor: 'profile.firstName' },
	{ Header: 'Email', accessor: 'email' },
	{ Header: 'Date registered', accessor: 'created_at' },
	{ Header: 'Place registered'},
	
	


];
const Vendors = () => {
	const [loading, setLoading] = React.useState(false);
	const { token } = useSelector((state) => state.auth);
	const [data, setData] = React.useState([]);

	React.useEffect(() => {
		async function getVendors() {
			setLoading(true);
			axios({
				method: 'GET',
				url: `${backendURL}/api/user/vendors`,
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
				.then((response) => {
					const vendors = response.data.vendors.map((vendor) => {
						const year = vendor.createdAt.split('T')[0].slice(0, 4);
						const month = vendor.createdAt.split('T')[0].slice(5, 7);
						vendor.createdAt = month + '/' + year;
						return { ...vendor, url: vendor._id };
					});
					setData(vendors);
					setLoading(false);
				})
				.catch((error) => {
					toast.error(error.response?.data || error.message);
					setLoading(false);
				});
		}
		getVendors();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Breadcrumbs title='Vendors' />
			<Container className='py-3 fluid'>
				{loading ? (
					<h2 className='text-center'>fetching vendors, please wait...</h2>
				) : data.length === 0 ? (
					<h2 className='text-center'>no vendors found</h2>
				) : (
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 table-responsive table-responsive-xxl'>
						<VendorsTable columns={columns} data={data} />
					</div>
				)}
			</Container>
		</>
	);
};

export default Vendors;
