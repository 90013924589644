import * as React from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaEnvelope } from 'react-icons/fa';
import { backendURL } from '../config/data';

const ForgotPassword = ({ show, handleClose }) => {
	const accessLevel = useSelector((state) => state.auth.user?.accessLevel);
	const [resetEmail, setEmail] = React.useState('');

	const resetPassword = async (e) => {
		e.preventDefault();
		if (!resetEmail) return toast.error('Email field cannot be empty');
		try {
			const res = await axios.post(
				`${backendURL}/api/user/forgotpassword`,
				{ email: resetEmail, accessLevel },
				{ headers: { 'Content-Type': 'application/json' } }
			);
			toast.info(res.data.message);
			handleClose();
		} catch (error) {
			toast.error(error?.response?.data?.message || error.message);
		}
	};

	return (
		<Modal show={show} onHide={handleClose} centered>
			<Modal.Header className='bg-primary text-white' closeButton>
				<Modal.Title>Reset Password</Modal.Title>
			</Modal.Header>
			<Form onSubmit={resetPassword}>
				<Modal.Body>
					<Form.Group className='mb-3' controlId='formBasicEmail'>
						<Form.Label>Email address</Form.Label>
						<InputGroup>
							<InputGroup.Text>
								<FaEnvelope />
							</InputGroup.Text>
							<Form.Control
								type='email'
								name='resetEmail'
								placeholder='Enter email'
								value={resetEmail}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</InputGroup>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='danger' type='submit'>
						Reset Password
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default ForgotPassword;
