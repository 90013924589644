import React from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { FaCartPlus, FaMoneyCheckAlt, FaQuestionCircle, FaUsers, FaUsersCog } from 'react-icons/fa';
import Breadcrumbs from './layouts/breadcrumb';

// Custom styles
const iconStyle = {
	fontSize: '65px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	color: 'var(--bs-primary)',
	height: '100%',
};

const cardStyle = {
	border: '1px solid rgba(94, 114, 228, .3)',
	height: '100%',
};

const cardFooterStyle = {
	backgroundColor: 'rgba(94, 114, 228, 0.3)',
	border: '1px solid rgba(94, 114, 228, 0.3)',
};

const Home = () => {
	return (
		<>
			<Breadcrumbs title='Dashboard' />
			<Container>
				<Row className='mb-5'>
					<Col lg='3' sm='6' className='mb-4'>
						<Card className='card-stats dashboard-card' style={cardStyle}>
							<Card.Body>
								<Row className='h-100'>
									<Col xs='5'>
										<div style={iconStyle}>
											<FaUsers />
										</div>
									</Col>
									<Col xs='7' className='d-flex align-items-center'>
										<div className='numbers'>
											<p className='card-category mb-2 text-red-300'>TOTAL USERS</p>
											<Card.Title as='h3' className='text-primary fw-bolder'>
												1233
											</Card.Title>
										</div>
									</Col>
								</Row>
							</Card.Body>
							<Card.Footer style={cardFooterStyle}></Card.Footer>
						</Card>
					</Col>

					<Col lg='3' sm='6' className='mb-4'>
						<Card className='card-stats' style={cardStyle}>
							<Card.Body>
								<Row className='h-100'>
									<Col xs='5'>
										<div style={iconStyle}>
											<FaUsersCog />
										</div>
									</Col>
									<Col xs='7' className='d-flex align-items-center'>
										<div className='numbers'>
											<p className='card-category mb-2'>TOTAL VENDORS</p>
											<Card.Title as='h3' className='text-primary fw-bolder'>
												43
											</Card.Title>
										</div>
									</Col>
								</Row>
							</Card.Body>
							<Card.Footer style={cardFooterStyle}></Card.Footer>
						</Card>
					</Col>

					<Col lg='3' sm='6' className='mb-4'>
						<Card className='card-stats' style={cardStyle}>
							<Card.Body>
								<Row className='h-100'>
									<Col xs='5'>
										<div style={iconStyle} className='text-primary'>
											<FaCartPlus />
										</div>
									</Col>
									<Col xs='7' className='d-flex align-items-center'>
										<div className='numbers'>
											<p className='card-category mb-2'>TOTAL ORDERS</p>
											<Card.Title as='h3' className='text-primary fw-bolder'>
												23434
											</Card.Title>
										</div>
									</Col>
								</Row>
							</Card.Body>
							<Card.Footer style={cardFooterStyle}></Card.Footer>
						</Card>
					</Col>

					<Col lg='3' sm='6' className='mb-4'>
						<Card className='card-stats' style={cardStyle}>
							<Card.Body>
								<Row className='h-100'>
									<Col xs='5'>
										<div style={iconStyle}>
											<FaMoneyCheckAlt />
										</div>
									</Col>
									<Col xs='7' className='d-flex align-items-center'>
										<div className='numbers'>
											<p className='card-category mb-2'>REVENUE PROCESSED</p>
											<Card.Title as='h3' className='text-primary fw-bolder'>
												$121
											</Card.Title>
										</div>
									</Col>
								</Row>
							</Card.Body>
							<Card.Footer style={cardFooterStyle}></Card.Footer>
						</Card>
					</Col>

					<Col lg='3' sm='6' className='mb-4'>
						<Card className='card-stats' style={cardStyle}>
							<Card.Body>
								<Row className='h-100'>
									<Col xs='5'>
										<div style={iconStyle}>
											<FaQuestionCircle />
										</div>
									</Col>
									<Col xs='7' className='d-flex align-items-center'>
										<div className='numbers'>
											<p className='card-category mb-2'>COMPLAINTS</p>
											<Card.Title as='h3' className='text-primary fw-bolder'>
												250/500
											</Card.Title>
											<p className='border border-3 border-danger py-2 px-1 mb-0 d-inline-block rounded-circle text-danger fw-bold'>
												50%
											</p>
										</div>
									</Col>
								</Row>
							</Card.Body>
							<Card.Footer style={cardFooterStyle}></Card.Footer>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default Home;
