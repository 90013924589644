import React from 'react';
import { Outlet } from 'react-router-dom';
import DashboardSidebar from './sidebar';
import DashboardHeader from './header';

const DashboardLayout = () => {
	return (
		<div className='dashboard-wrapper d-flex'>
			<DashboardSidebar />
			<div className='flex-grow-1 d-flex flex-column'>
				<DashboardHeader />
				<main className='flex-grow-1 px-1 py-2 p-md-3 d-flex flex-column align-items-center md-5 bg-white'>
					<Outlet />
				</main>
			</div>
		</div>
	);
};

export default DashboardLayout;
